import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CenterContent from "../../components/CenterContent";

//img
import DummyImg from "./img/dummy.webp";

const EventCrousel = () => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,

        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    }

    const centerData = {
        miniText: "",
        title: "Upcoming Events",
        spanText: "",
        spanIndex: "Events",
        content: "Enhance your experience with our range of add-ons, designed to further optimize your process."
    }
    return (
        <div className="event-crousel">
            <div className="container">
                <CenterContent miniText={centerData.miniText} title={centerData.title} spanText={centerData.spanText} spanIndex={centerData.spanIndex} content={centerData.content} />
            </div>

            <div className="event-crousel__slider">
                <div className="container">

                    <Slider {...settings}>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <span className="date">2024-09-30</span>

                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <span className="date">2024-09-30</span>

                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <span className="date">2024-09-30</span>

                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <span className="date">2024-09-30</span>

                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <span className="date">2024-09-30</span>

                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>
                        <div className="event-card">
                            <div className="event-card__img">
                                <img src={DummyImg} alt="dummy" />
                            </div>

                            <div className="event-card__content">
                                <h2 className="event-card__title"> Event Title</h2>
                                <p>Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data</p>
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>

        </div>
    )
}

export default EventCrousel;