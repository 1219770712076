// import './style.scss';
import Logo from '../img/logo.png';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//feather-icon

// import {Menu} from "react-feather";

import { Link} from 'react-router-dom';

//contact modal

import ContactModal from '../../Modals/ContactModal';


const HorizontalNav = ({ isSidebarActive, setSidebarIsActive }) => {
    const [isActive, setIsActive] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();

    const handleMenuClick = () => {
        setSidebarIsActive();
        // setIsActive(!isActive);

    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const toggleContactModal = ()=>{
        // setShowContactModal(!showContactModal);
        window.open("https://app.insightai.in/register", "_blank");
    }

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 0) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <nav className={`navigation ${isScrolled ? 'scrolled' : ''}`}>
            <div className="container">
                <div className='navigation__nav-items'>
                    <Link to="/" className='logo' onClick={scrollToTop}>
                        <img src={Logo} alt='Insight Ai' className='main-logo' />
                    </Link>
                    <Link to="/" className='nav-item' onClick={scrollToTop}>Home</Link>
                    <Link to="/about-us" className='nav-item' onClick={scrollToTop}>About Us</Link>
                    <Link to="/solutions" className='nav-item' onClick={scrollToTop}>Solutions</Link>
                    <Link to="/blogs" className='nav-item' onClick={scrollToTop}>Blogs</Link>
                    {/* <Link to="/pricing" className='nav-item' onClick={scrollToTop}>Pricing</Link> */}
                    <Link to="/ecommerce" className='nav-item' onClick={scrollToTop}>Ecommerce</Link>
                    <Link to="/faqs" className='nav-item' onClick={scrollToTop}>FAQs</Link>
                    <Link to="/events" className='nav-item' onClick={scrollToTop}>Events</Link>
                    <Link to="/contact" className='nav-item' onClick={scrollToTop}>Contact</Link>
                </div>

                <button className='btn-dark nav-btn' data-text="Get started for Free!" onClick={toggleContactModal}><span className='btn-wrapper'>Get started for Free!</span></button>
                {/* Menu Button */}
                <button className={`hamburger ${isSidebarActive ? 'active' : ''}`} onClick={handleMenuClick}><span className='menu-line'></span></button>
            </div>
            <ContactModal showContactModal={showContactModal} toggleContactModal={toggleContactModal} />
        </nav>
    )
}

export default HorizontalNav;