//img
import DummyImg from "./img/dummy.webp";
import React, { useState } from 'react';

import CenterContent from "../../components/CenterContent";

const events = [
    {
        id: 1,
        title: "Event Title 1",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-08-15"
    },
    {
        id: 2,
        title: "Event Title 2",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-08-10"
    },
    {
        id: 3,
        title: "Event Title 3",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-08-05"
    },
    {
        id: 4,
        title: "Event Title 4",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-07-30"
    },
    {
        id: 5,
        title: "Event Title 5",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-07-25"
    },
    {
        id: 6,
        title: "Event Title 6",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-07-20"
    },
    {
        id: 7,
        title: "Event Title 7",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-07-15"
    },
    {
        id: 8,
        title: "Event Title 8",
        description: "Effortlessly connect with Zoho to gain enhanced financial insights, automate routine processes, and improve overall efficiency in managing your financial data",
        image: DummyImg,
        date: "2024-07-10"
    },
];


const PastEvents = () => {
    const [visibleEvents, setVisibleEvents] = useState(3);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleLoadMore = () => {
        if (isExpanded) {
            setVisibleEvents(3); // Show only 4 events when showing less
        } else {
            setVisibleEvents(events.length); // Show all events when loading more
        }
        setIsExpanded(!isExpanded); // Toggle the state
    };

    const centerData = {
        miniText: "",
        title: "Past Events",
        spanText: "",
        spanIndex: "Events",
        content: "Enhance your experience with our range of add-ons, designed to further optimize your process."
    }
    return (
        <div className="past-events">
            <div className="container">
            <CenterContent miniText={centerData.miniText} title={centerData.title} spanText={centerData.spanText} spanIndex={centerData.spanIndex} content={centerData.content} />
                <div className="past-events__inner">
                    {events.slice(0, visibleEvents).map(event => (
                        <div key={event.id} className="past-events__card">
                            <div className="past-events__card-image">
                                <img src={event.image} alt={event.title} />
                            </div>
                            <div className="past-events__card-content">
                                <span className="date">{event.date}</span>
                                <h2 className="past-events__title">{event.title}</h2>
                                <p>{event.description}</p>
                            </div>
                        </div>
                    ))}

                </div>

                {events.length > 3 && (
                    <div className="load-more-button-wrapper">
                        <button onClick={handleLoadMore} className="btn-border" data-text={isExpanded ? "Show Less" : "Load More"}>
                            <span className="btn-wrapper">
                                {isExpanded ? "Show Less" : "Load More"}
                            </span>
                        </button>
                    </div>
                )}

            </div>
        </div>

    )
}

export default PastEvents;