import { Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import AboutUs from '../Pages/AboutUs';
import BlogPage from '../Pages/Blog';
import ContactUs from '../Pages/ContactUs';
import Help from '../Pages/Help';
import Solutions from '../Pages/Solutions';
import Pricing from '../Pages/Pricing';
import BlogSingle from '../Pages/Blog/blogSingle';
import TermsNConditions from '../Pages/Terms';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import Ecommerce from '../Pages/Ecommerce';
import Events from '../Pages/Events';

const MyRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/blogs/blog-single/:id" element={<BlogSingle />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/faqs" element={<Help />} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            <Route path="/terms-nd-conditions" element={<TermsNConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/events" element={<Events />} />


        </Routes>
    );
};


export default MyRoutes;